import { debounce, isObject } from 'utils';
import { makeDateTimePickerRelativeValues } from 'utils/dates';
import filtersStorage from 'libs/FiltersStorage';
import { parse } from 'qs';
import {
	checkFilterValues,
	getCurrentBrowse,
	getFiltersValues,
	getValueFromFilter,
	isEmptyValue,
	removeFiltersWithEmptyValues
} from './actions-utils';
import {
	CHANGE_FILTER,
	CHANGE_FILTERS,
	CLEAR_FILTER,
	CLEAR_ALL_FILTERS,
	APPLY_FILTERS,
	APPLY_FILTERS_TOGGLE,
	SET_DEFAULTVALUES
} from '../types';
import { fetchData } from './fetch';
import { changeSort } from './sort';

const avoidDefaultFilters = (filtersToSave, filters) => {
	const filtersObj = {};
	const defaultFilters = filters.filter(filter => filter.defaultValue);

	if (!defaultFilters.length) return filtersToSave;

	Object.entries(filtersToSave).forEach(filter => {
		const [filterName, filterValue] = filter;

		const defaultFilter = defaultFilters.find(defFilter => defFilter.name === filterName);

		if (!defaultFilter) {
			filtersObj[filterName] = filterValue;
			return;
		}

		const { defaultValue } = defaultFilter || {};

		const sameDefaultValue = checkFilterValues(filterValue, defaultValue);

		filtersObj[filterName] = !sameDefaultValue ? filterValue : '';
	});
	return filtersObj;
};

const saveFiltersInStorage = (filtersToSave, schema) => {
	const { service = '', name = '' } = schema || {};
	filtersStorage.insertFilters(`${service}-${name}`, filtersToSave);
};

const clearAllFiltersInStorage = schema => {
	const { service = '', name = '' } = schema || {};
	filtersStorage.clearFilters(`${service}-${name}`);
};

const saveFiltersFromUrl = schema => {
	const paramObj = parse(window.location.search, { ignoreQueryPrefix: true });
	const filtersWithoutDefaultFilters = avoidDefaultFilters(paramObj?.filters, schema?.filters);
	saveFiltersInStorage(filtersWithoutDefaultFilters, schema);
};

export const changeFilter = (name, value) => currentBrowse => ({
	type: CHANGE_FILTER,
	name,
	value,
	meta: { name: currentBrowse }
});

export const changeFilters = values => currentBrowse => ({
	type: CHANGE_FILTERS,
	values,
	meta: { name: currentBrowse }
});

const clearFilter = name => currentBrowse => ({
	type: CLEAR_FILTER,
	name,
	meta: { name: currentBrowse }
});

export const clearAllFiltersAction = () => currentBrowse => ({
	type: CLEAR_ALL_FILTERS,
	meta: { name: currentBrowse }
});

export const handleApplyFiltersToggle = () => currentFilters => ({
	type: APPLY_FILTERS_TOGGLE,
	meta: { name: currentFilters }
});

const fetchDataDebounced = debounce((dispatch, currentBrowse) => {
	dispatch(handleApplyFiltersToggle()(currentBrowse));
	dispatch(fetchData()(currentBrowse));
}, 500);

export const setApplyFiltersFlag = value => currentBrowse => ({
	type: APPLY_FILTERS,
	meta: { name: currentBrowse },
	value
});
export const setInitialDefaultValues = values => currentBrowse => ({
	type: SET_DEFAULTVALUES,
	values,
	meta: { name: currentBrowse }
});

export const setDefaultValues = values => currentBrowse => dispatch => {
	dispatch(setInitialDefaultValues(values)(currentBrowse));
};

export const clearSingleFilter = name => currentBrowse => (dispatch, getState) => {
	const {
		schema: { service, name: schemaName }
	} = getCurrentBrowse(getState(), currentBrowse);
	filtersStorage.clearFilter(`${service}-${schemaName}`, name);
	dispatch(clearFilter(name)(currentBrowse));
	dispatch(handleApplyFiltersToggle()(currentBrowse));
	dispatch(fetchData()(currentBrowse));
	dispatch(setApplyFiltersFlag(true)(currentBrowse));
};

export const clearAllFilters = currentBrowse => (dispatch, getState) => {
	const { schema } = getCurrentBrowse(getState(), currentBrowse);
	clearAllFiltersInStorage(schema);
	dispatch(clearAllFiltersAction()(currentBrowse));
	dispatch(handleApplyFiltersToggle()(currentBrowse));
	dispatch(fetchData()(currentBrowse));
	dispatch(setApplyFiltersFlag(true)(currentBrowse));
};

export const changeFilterHelper = (
	name,
	currentValue,
	saveFilters = false,
	applyFilterValue = false
) => currentBrowse => (dispatch, getState) => {
	const { schema } = getCurrentBrowse(getState(), currentBrowse);

	const parsedValue = isObject(currentValue) ? currentValue?.value : currentValue;
	if (isEmptyValue(parsedValue)) dispatch(clearFilter(name)(currentBrowse));
	else dispatch(changeFilter(name, parsedValue)(currentBrowse));
	if (saveFilters) saveFiltersInStorage({ [name]: getValueFromFilter(parsedValue) }, schema);
	dispatch(setApplyFiltersFlag(applyFilterValue)(currentBrowse));
};

export const setFiltersInStorage = (values = null) => currentBrowse => (_, getState) => {
	const { schema, appliedFilters } = getCurrentBrowse(getState(), currentBrowse);
	const valuesToSave = values || appliedFilters;
	const parsedValues = getFiltersValues(valuesToSave);
	saveFiltersInStorage(avoidDefaultFilters(parsedValues, schema?.filters), schema);
};

/**
 * Action to apply filters simultaneously
 * @param {object} values
 */
export const changeAndApplyFilters = values => currentBrowse => dispatch => {
	const nonEmptyValues = removeFiltersWithEmptyValues(values);
	dispatch(setFiltersInStorage(nonEmptyValues)(currentBrowse));
	dispatch(changeFilters(nonEmptyValues)(currentBrowse));
	dispatch(handleApplyFiltersToggle()(currentBrowse));
	dispatch(fetchData()(currentBrowse));
};

/**
 * Action to apply filters one by one
 * @param {string} name
 * @param {string|number|array} currentValue
 */
export const changeAndApplyFilter = (name, currentValue) => currentBrowse => dispatch => {
	dispatch(changeFilterHelper(name, currentValue, true)(currentBrowse));
	dispatch(handleApplyFiltersToggle()(currentBrowse));
	dispatch(fetchData()(currentBrowse));
};

/**
 * Action to apply filters one by one debounced
 * @param {string|number|array} currentValue
 */
export const changeAndApplyFilterDebounced = (name, currentValue) => currentBrowse => dispatch => {
	dispatch(changeFilterHelper(name, currentValue, true)(currentBrowse));
	fetchDataDebounced(dispatch, currentBrowse);
};

/**
 * Check current filters in URL for dispatch actions for change filters in store
 * @param {object} parsedParams
 */
export const checkUrlFilter = parsedParams => currentBrowse => (dispatch, getState) => {
	const { browses } = getState();

	const browse = browses[currentBrowse];

	const filtersComponents = browse.filters || [];
	const sortableFields = (browse.schema && browse.schema.sortableFields) || [];

	Object.keys(parsedParams).forEach(keyParam => {
		const valueParam = parsedParams[keyParam];

		if (keyParam === 'filters') {
			const filters = valueParam;
			const filterKeys = Object.keys(filters);

			filterKeys.forEach(key => {
				const currentFilterComponent = filtersComponents.find(filter => filter.name === key);
				const filterValue = makeDateTimePickerRelativeValues(currentFilterComponent, filters);

				dispatch(changeFilter(key, filterValue || filters[key])(currentBrowse));
			});

			saveFiltersFromUrl(browse.schema);
			dispatch(handleApplyFiltersToggle()(currentBrowse));
		}
	});

	const { sortBy, sortDirection } = parsedParams;

	const intialSortBy = sortableFields
		.filter(option => sortBy && sortBy.includes(option.name))
		.map((sort, index) => ({
			...sort,
			initialSortDirection: Array.isArray(sortDirection)
				? sortDirection[index]
				: sortDirection || sort.initialSortDirection
		}));

	if (intialSortBy.length) dispatch(changeSort(intialSortBy)(currentBrowse));
};
